import React from 'react'

export interface NavBarProps {
  left?: React.ReactNode
  title?: React.ReactNode
  right?: React.ReactNode
}

export const NavBar = ({
  left = <div className="h-full w-14"></div>,
  title = '',
  right = <div className="h-full w-14"></div>,
}: NavBarProps) => {
  return (
    <div className="js-dialog-offset fixed inset-x-0 top-0 z-10 flex h-14 items-center justify-between border-b bg-surface-ui-navbar text-foreground-ui-navbar">
      {left}

      <div className="flex-1 truncate px-4 py-3 text-center my-heading-sm">
        {title}
      </div>

      {right}
    </div>
  )
}
